<template>
  <div class="w-full">
    <multiselect
      :searchable="true"
      :internal-search="false"
      :preserve-search="true"
      :multiple="noMulti"
      :loading="isLoading"
      :options="specialities"
      @search-change="onSearchSpeciality"
      @remove="removeItem"
      v-model="ItemsSelected"
      track-by="name"
      label="name"
      placeholder=""
      :class="custemClass ? custemClass : ''"
    >
      <template slot="noResult">Il n'y a aucun résultat</template>
      <template slot="noOptions">Saisissez une spécialité!</template>
      <template slot="caret">
        <span
          class="absolute right-0 top-0 h-full flex items-center justify-center px-2"
        >
          <img src="/svg/chevron-down .svg" />
        </span>
      </template>
    </multiselect>
  </div>
</template>

<script>
export default {
  props: {
    cityChoice: {
      type: String,
      required: false
    },
    selectedItems: {
      type: Array,
      required: true
    },
    custemClass: {
      type: String,
      required: false
    },
    noMulti: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      isLoading: false,
      specialities: [],
      ItemsSelected: []
    };
  },
  created() {
    this.ItemsSelected = this.selectedItems;
  },
  watch: {
    ItemsSelected(newVal, oldVal) {
      if (newVal && newVal.name) {
        this.$emit("update:cityChoice", newVal ? newVal.name : "");
      }
      this.$emit("update:selected-items", this.ItemsSelected);
    }
  },
  methods: {
    async onSearchSpeciality(search) {
      this.isLoading = true;
      const data = await this.$store.dispatch("doctor/GET_SPECIALITIES_LIST", {
        params: {
          term: search,
          limit: 10
        }
      });

      if (data.ok) {
        this.specialities = data.data;
        this.isLoading = false;
      }
    },
    removeItem(item, key) {
      if (this.cityChoice !== "") {
        this.$emit("update:cityChoice", "");
        return;
      }
      this.ItemsSelected = this.ItemsSelected.filter(xitem => {
        return xitem._id !== item._id;
      });
    }
  }
};
</script>
